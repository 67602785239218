<template>
  <div class="content-page">
    <div class="d-flex align-items-center justify-content-between px-3">
      <b-button variant="custom-link" @click="backToView()">
        <font-awesome-icon icon="arrow-left"
      /></b-button>
      <div class="header-title">
        <div>RECEIPT</div>
      </div>
      <!-- <b-button variant="custom-link" @click="printPdf()">
        <font-awesome-icon icon="file-download"
      /></b-button> -->
    </div>

    <template v-if="data.url_pdf">
      <div class="pdf-viewer mt-3">
        <template v-if="data.url_pdf.includes('.pdf')">
          <OtherLoading v-if="pdfLoading" />
          <template v-else>
            <pdf
              ref="myPdfComponent"
              v-for="i in page"
              :key="i"
              :src="src"
              :page="i"
              class="pdf-view"
            ></pdf>
          </template>
        </template>
        <b-img v-else class="w-100" :src="data.url_pdf"></b-img>
        <b-button
          class="w-100 btn-register mt-3"
          type="button"
          @click="printPdf()"
        >
          Download
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: { pdf, OtherLoading },
  data() {
    return {
      isShow: 1,
      showFilter: false,
      filter: {
        month: this.$moment().months() + 1,
        year: this.$moment().year(),
        page: 1,
        pageSize: 5
      },
      data: [],
      pdfLoading: true,
      page: 1,
      src: null
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleFilter(val) {
      if (val == 1) {
        this.filter.month = this.$moment().months() + 1;
        this.filter.year = this.$moment().year();
      } else if (val == 2 || val == 3) {
        let sub = val == 2 ? 3 : 6;
        this.filter.month = this.$moment().subtract(sub, "month").months() + 1;
        this.filter.year = this.$moment().subtract(sub, "month").year();
      } else {
        this.filter.month = this.$moment().subtract(1, "year").months() + 1;
        this.filter.year = this.$moment().subtract(1, "year").year();
      }
      this.isShow = val;
    },
    updateFilter() {
      this.getData();
      this.showFilter = false;
    },
    getData() {
      this.$axios(
        `${process.env.VUE_APP_API}/api/Transaction/customer_transaction/${this.$route.params.id}`
      ).then(async data => {
        this.data = data.detail;
        if (data.detail.url_pdf.includes(".pdf")) this.renderingPDF();
      });
    },
    viewRecipt(id) {
      return this.$router.push("/transaction-summary/" + id);
    },
    backToView() {
      return this.$router.push("/transaction-summary/" + this.$route.params.id);
    },
    printPdf() {
      this.$liff.openWindow({
        url: this.data.url_pdf,
        external: true
      });
      //   fetch(this.data.url_pdf)
      //     .then(response => response.blob())
      //     .then(blob => {
      //       const link = document.createElement("a");
      //       link.href = URL.createObjectURL(blob);
      //       link.download = `recipt-${this.data.invoice_no}-${this.$moment(
      //         this.data.transaction_date
      //       ).format("DDMMYYYYHH:mmA")}.pdf`;
      //       link.click();
      //     })
      //     .catch(console.error);
    },
    renderingPDF() {
      let src = pdf.createLoadingTask(this.data.url_pdf);
      this.src = src;
      src.promise.then(pdf => {
        this.page = pdf.numPages;
        this.pdfLoading = false;
      });
    }
  }
};
</script>

<style lang="scss">
.header-title {
  font-weight: 700;
  font-size: 16px;
  margin: 1.25rem 0;
  display: inline-flex;
  align-items: center;
  letter-spacing: 1px;

  justify-content: center;
  width: 100%;
  position: relative;
}
.filter-icon {
  position: absolute;
  width: 24px !important;
  height: auto;
  aspect-ratio: 1;
  right: 0;
}
.text-price {
  color: #b00020;
  font-weight: 500;
}
.badge-custom {
  color: black;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.1);
}
.filter-panel {
  width: 100%;
  height: 40vh;
  position: fixed;
  z-index: 50;
  background: white;
  bottom: 0;
  left: 0;
  border-radius: 0.75rem;
  padding: 1.25rem;
}
@media screen and (max-height: 700px) {
  .filter-panel {
    height: 50vh;
  }
}
.dropdown-backdrop {
  height: 100vh;
  width: 100%;
  background: #00000042;
  position: fixed;
  top: 0;
  left: 0;
}
.filter-container {
  display: flex;
  height: 70%;
  flex-direction: column;
}
.filter-chioce {
  &.isActive {
    background-color: rgba(239, 239, 239, 1);
  }
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  border-radius: 8px;
  color: black;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.logo-img {
  width: 100%;
  max-width: 150px;
  height: auto;
  //   aspect-ratio: 16/9;
  //   object-fit: contain;
}
.product {
  width: 60%;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
  font-weight: 700;
}
.product-container {
  // min-height: 20vh;
}
hr.dash {
  border-style: dashed;
}
.btn-recipt {
  width: 100%;
}
.recipt-footer {
  position: absolute;
  bottom: 0;
  padding: 15px;
  left: 0;
  width: 100%;
  margin-bottom: 70px;
}

@media screen and (max-width: 400px) {
  .recipt-footer {
    position: relative;
    bottom: 0;
    padding: 15px 0;
    left: 0;
    width: 100%;
  }
}
.pdf-viewer {
  // margin-top: 50px;
  overflow-x: hidden;
  padding: 0.5rem;
}
.pdf-view {
  //   padding: 0.5rem;d
}
.content-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.5rem;
  margin: auto;
  position: fixed;
  z-index: 1;
  left: 0;
  background-color: white;
}
.qty {
  width: 15%;
  text-align: center;
}
.w-1 {
  width: 65%;
}
.amount {
  width: 20%;
  text-align: right;
}
.receipt-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
